/* eslint-disable import/prefer-default-export */
const errorMessageConstant = {
  NotAuthorizedException: 'Incorrect Password',
  'Incorrect username or password.':
    'The current password you entered is incorrect, please try again',
  LimitExceededException: 'LimitExceededException',
  'Attempt limit exceeded, please try after some time.':
    'Attempt limit exceeded, please try after some time.',
}

export { errorMessageConstant }
