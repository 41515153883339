const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  REGISTER: 'user/REGISTER',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
  COMPLETE_NEW_PASSWORD: 'user/COMPLETE_NEW_PASSWORD',
  COMPLETE_FORGOT_PASSWORD: 'user/COMPLETE_FORGOT_PASSWORD',
  FORGOT_PASSWORD_EMAIL: 'user/FORGOT_PASSWORD_EMAIL',
  CHANGE_PASSWORD: 'user/CHANGE_PASSWORD',
  SELECT_PATIENT: 'user/SELECT_PATIENT',
  AVAILABLE: 'AVAILABLE',
}

export default actions
