const actions = {
  SET_STATE: 'meeting/SET_STATE',
  ADD_VIRTUAL_INVOCATION: 'meeting/ADD_VIRTUAL_INVOCATION',
  REMOVE_VIRTUAL_INVOCATION: 'meeting/REMOVE_VIRTUAL_INVOCATION',
  EMPTY_VIRTUAL_INVOCATION: 'meeting/EMPTY_VIRTUAL_INVOCATION',
  CALL_END: 'meeting/CALL_END',
  SET_CALL_ID: 'meeting/SET_CALL_ID',
}

export default actions
