import localeAntd from 'antd/es/locale/en_US'

const messages = {
	'topBar.issuesHistory': 'Issues',
	'topBar.projectManagement': 'Projects',
	'topBar.typeToSearch': 'Search...',
	'topBar.findPages': 'Find pages...',
	'topBar.actions': 'Actions',
	'topBar.status': 'Status',
	'topBar.profileMenu.billingPlan': 'Billing Plan',
	'topBar.profileMenu.role': 'Role',
	'topBar.profileMenu.email': 'Email',
	'topBar.profileMenu.phone': 'Phone',
	'topBar.profileMenu.editProfile': 'Edit Profile',
	'topBar.profileMenu.manageUsers': 'Change Password',
	'topBar.profileMenu.manageUsage': 'Usage Stats',
	'topBar.profileMenu.logout': 'Logout',
	'topBar.demo': 'Today’s Appointments',
}

export default {
	locale: 'en-US',
	localeAntd,
	messages,
}
