import actions from './actions'

const initialState = {
	setConversation: null,
}

export default function chatReducer(state = initialState, action) {
	switch (action.type) {
		case actions.SET_CONVERSATION:
			return { ...state, setConversation: action.payload }
		default:
			return state
	}
}
